import React, { forwardRef, useImperativeHandle, useState, useMemo } from "react";
import classNames from "common/class-names";

const AppModalAlert = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [message, setMessage] = useState("");

	//prettier-ignore
	const appClassName = useMemo(() => classNames({
		"app-modal-alert": true,
		"app-modal-alert--active": visible,
	}), [visible]);

	const onHandleShow = (text) => {
		setVisible(true);
		setMessage(text);
	};

	const onHandleDismiss = () => {
		setVisible(false);
		setMessage("");
	};

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<div className={appClassName}>
			<div className="modal-alert">
				<div className="modal-alert__card">
					<p className="modal-alert__description">{message}</p>

					<button type="button" className="modal-alert__button" onClick={onHandleDismiss}>
						OK
					</button>
				</div>
			</div>
		</div>
	);
};

export default forwardRef(AppModalAlert);
