import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import COMMON from "common";
import icon from "assets/images/network-error-icon.png";

const PageConnectionFailed = () => {
	const navigate = useNavigate();

	const onHandleRetry = () => {
		navigate(-1);
	};

	useEffect(() => {
		if (window?.directPostMessage) window.directPostMessage(JSON.stringify({ action: COMMON.EVENTS.FAILED }));
	}, []);

	return (
		<div className="app-page page-connection-failed">
			<div className="connection-failed">
				<img className="connection-failed__icon" src={icon} alt="network error" />
				<p className="connection-failed__title">No Connection Found</p>
				<p className="connection-failed__description">Please check your internet connection, you are in offline now.</p>

				<button type="button" className="connection-failed__button" onClick={onHandleRetry}>
					Retry
				</button>
			</div>
		</div>
	);
};

export default PageConnectionFailed;
