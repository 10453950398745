import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

import classNames from "common/class-names";
import { ReactComponent as CheckIcon } from "assets/images/check-icon.svg";

const AppCheckbox = (props) => {
	const activated = useMemo(() => props.value, [props.value]);

	const className = useMemo(() => {
		return classNames({
			"app-checkbox__button": true,
			"app-checkbox__button--active": activated,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, activated]);

	return (
		<div className="app-checkbox">
			<button className={className} type="button" name={props.name} disabled={props.disabled} checked={activated} onClick={props.onClick}>
				<div className="app-checkbox__box">
					<CheckIcon className="app-checkbox__icon" />
				</div>
			</button>
			{props.label && <p className="app-checkbox__label">{props.label}</p>}
		</div>
	);
};

AppCheckbox.propTypes = {
	value: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default memo(AppCheckbox);
