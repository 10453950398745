import { createContext, memo, useEffect, useRef, useCallback, useState } from "react";

import COMMON from "common";
import services from "services";

const sharedStyles = "margin: 5px 0; padding: 8px; border-radius: 20px; color: #FFFFFF;";
const initStyles = `${sharedStyles} background: #673AB7;`;
const ejectStyles = `${sharedStyles} background: #ffb127;`;
const reqDataStyles = `${sharedStyles} background: #F8EA8C;`;
const reqParamsStyles = `${sharedStyles} background: #ff9514;`;
const respSuccessStyles = `${sharedStyles} background: #32ac97;`;
const errorStyles = `${sharedStyles} background: #F79489;`;
const debug = process.env.NODE_ENV === "development";

const interceptorDebug = (title, styles, data = "") => {
	if (!debug) return;
	console.log(`%c ${title}`, styles);
	if (data) {
		const isMobile = sessionStorage.getItem(COMMON.DEVICE.MOBILE);
		if (isMobile) {
			console.log(JSON.stringify(data));
		} else {
			console.log(data);
		}
	}
	console.log("");
};

export const AxiosContext = createContext();

const InterceptorProvider = ({ children }) => {
	const controller = useRef({});
	const [initialed, setInitialed] = useState(false);
	const requestInterceptor = useRef(null);
	const responseInterceptor = useRef(null);

	const onHandleRequest = useCallback((config) => {
		switch (config.method) {
			case "get":
				interceptorDebug(`GET REQUESTING 🚀 ${config.baseURL + config.url}`, reqParamsStyles, config.params);
				break;
			case "post":
				interceptorDebug(`POST REQUESTING 🚀 ${config.baseURL + config.url}`, reqDataStyles, config.data);
				break;
			default:
				break;
		}

		let signal = {};

		if (config.cancelId) {
			controller.current[config.cancelId] = new AbortController();
			signal = { signal: controller.current[config.cancelId].signal };
		}

		const token = localStorage.getItem(COMMON.AUTH_TOKEN);

		if (token) config.headers.Authorization = "Bearer " + token;

		return { ...config, ...signal };
	}, []);

	const onHandleRequestError = useCallback((error) => {
		interceptorDebug("REQUESTING ERROR 👎", errorStyles, error);
		return Promise.reject(error);
	}, []);

	const onHandleResponse = useCallback((response) => {
		interceptorDebug(`RESPONSE SUCCESS: 🌟 ${response.config.baseURL + response.config.url}`, respSuccessStyles, response.data);
		return response.data;
	}, []);

	const onResponseError = useCallback(async (error) => {
		interceptorDebug(`RESPONSE ERROR 🥲 ${error.config.baseURL + error.config.url}`, errorStyles, error);

		return Promise.reject(error);
	}, []);

	const onHandleCancelRequest = useCallback((id) => {
		if (controller.current[id]) controller.current[id]?.abort();
	}, []);

	const onHandleEjectAxiosInterceptor = useCallback(() => {
		if (requestInterceptor.current !== null) {
			interceptorDebug("Ejected Request Axios Interceptor! 👋", ejectStyles);
			services.interceptors.request.eject(requestInterceptor.current);
		}
		if (responseInterceptor.current !== null) {
			interceptorDebug("Ejected Response Axios Interceptor! 👋", ejectStyles);
			services.interceptors.response.eject(responseInterceptor.current);
		}
	}, []);

	useEffect(() => {
		interceptorDebug(`Init Axios Interceptor! 🎉`, initStyles);
		requestInterceptor.current = services.interceptors.request.use(onHandleRequest, onHandleRequestError, { synchronous: true });
		responseInterceptor.current = services.interceptors.response.use(onHandleResponse, onResponseError, { synchronous: true });
		setInitialed(true);
	}, [onHandleRequest, onHandleRequestError, onHandleResponse, onResponseError]);

	useEffect(() => {
		return () => {
			onHandleEjectAxiosInterceptor();
		};
	}, [onHandleEjectAxiosInterceptor]);

	if (!initialed) return null;

	return <AxiosContext.Provider value={{ onHandleCancelRequest }}>{children}</AxiosContext.Provider>;
};

export default memo(InterceptorProvider);
