import React from "react";

const AppLoading = () => {
	return (
		<div className="app-loading">
			<div className="loading">
				<div className="loading__dot"></div>
				<div className="loading__dot"></div>
				<div className="loading__dot"></div>
				<div className="loading__dot"></div>
			</div>
		</div>
	);
};

export default AppLoading;
