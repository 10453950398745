const COMMON = {
	AUTH_TOKEN: "easy_cover_payment_@auth_tk",
	ENDPOINT_PATH: {
		BANKS: "/cgp/cgp-payment/v1/payment/overview/",
		PAY: "/cgp/cgp-payment/v1/payment/pay",
		CANCEL_PAYMENT: "/cgp/cgp-payment/v1/payment/cancel/",
		EXPIRE_PAYMENT: "/cgp/cgp-payment/v1/payment/expired/",
	},
	EVENTS: {
		LOADED: "LOADED",
		FAILED: "FAILED",
		SUCCESS: "SUCCESS",
	},
	DEVICE: {
		MOBILE: "MOBILE",
	},
	PAYMENT_TYPE: {
		CREDIT_CARD: "CREDIT_CARD",
		ONLINE_BANKING: "ONLINE_BANKING",
	},
	BANK_TYPE: {
		CORPORATE_BANKS: "CORPORATE_BANKS",
		RETAIL_BANKS: "RETAIL_BANKS",
	},
	PAYMENT_STATUS: {
		SUCCESS: "P00001",
		B2b_SUCCESS: "P00003",
		FAILED: "01",
	},
	CREDIT_CARD_FORMAT: {
		CARD_NO: "****-****-****-****",
		EXPIRY_DATE: "**/**",
	},
};

export default COMMON;
