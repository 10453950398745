import React, { useEffect, useMemo, useState, useCallback, useContext, Fragment, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import timerCounter from "common/timer-counter";
import sanitizeError from "common/sanitize-error";
import secondsToTime from "common/seconds-to-time";
// import validateCreditCard from "common/validate-credit-card";
import { formatCurrency } from "common/format-currency-pattern";
import { AxiosContext } from "contexts/with-interceptor-provider";
// import validateCreditCardDate from "common/validate-credit-card-date";
// import validateCreditCardType from "common/validate-credit-card-type";
// import AppInput from "components/app-input";
import AppLoading from "components/app-loading";
import AppCheckbox from "components/app-checkbox";
import AppModalAlert from "components/app-modal-alert";
// import AppMaskingInput from "components/app-masking-input";
import fpxLogo from "assets/images/fpx.png";
import checkIcon from "assets/images/check-icon.svg";
import chevronIcon from "assets/images/chevron-icon.svg";
import creditCardIcon from "assets/images/credit-card-icon.svg";

const PagePayment = () => {
	const alertRef = useRef();
	const navigate = useNavigate();
	const context = useContext(AxiosContext);
	const [searchParams] = useSearchParams();
	const [timer, setTimer] = useState(0);
	const [banks, setBanks] = useState({});
	const [payment, setPayment] = useState({});
	const [loading, setLoading] = useState(true);
	const [paymentMethods, setPaymentMethods] = useState({});
	const orderNo = useMemo(() => searchParams.get("orderNumber"), [searchParams]);
	//prettier-ignore
	const formik = useFormik({
		initialValues: { paymentType: "", bankType: "", bank: "", tnc: false, cc: { cardName: "", cardNumber: "", expiryDate: "", cvv: "" } },
		validationSchema: yup.object({
			paymentType: yup.string().required(ERRORS.REQUIRED),
			bank: yup.object().when(["paymentType"], { is: (type) => type === COMMON.PAYMENT_TYPE.ONLINE_BANKING, then: () => yup.string().required(ERRORS.INVALID_BANK), otherwise: () => yup.mixed().notRequired() }),
			tnc: yup.object().when(["paymentType"], { is: (type) => type === COMMON.PAYMENT_TYPE.ONLINE_BANKING, then: () => yup.boolean().oneOf([true], ERRORS.REQUIRED), otherwise: () => yup.mixed().notRequired() }),
			// cc: yup.object().when(["paymentType"], { is: (type) => type === COMMON.PAYMENT_TYPE.CREDIT_CARD, then: () =>
			// 	yup.object({
			// 		cardName: yup.string().required(ERRORS.REQUIRED),
			// 		cardNumber: yup.string().required(ERRORS.REQUIRED).test("fileSize", ERRORS.INVALID_CARD, validateCreditCard),
			// 		expiryDate: yup.string().required(ERRORS.REQUIRED).test("fileSize", ERRORS.INVALID_DATE, validateCreditCardDate),
			// 		cvv: yup.string().min(3, ERRORS.REQUIRED).required(ERRORS.REQUIRED),
			// 	}),
			// 	otherwise: () => yup.mixed().notRequired(),
			// }),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const setSubmitting = useMemo(() => formik.setSubmitting, [formik]);
	const disabledSubmit = useMemo(() => formik.isSubmitting || !timer, [timer, formik.isSubmitting]);
	const isOnlinePayment = useMemo(() => formik.values.paymentType === COMMON.PAYMENT_TYPE.ONLINE_BANKING, [formik.values.paymentType]);
	const isCreditCard = useMemo(() => formik.values.paymentType === COMMON.PAYMENT_TYPE.CREDIT_CARD, [formik.values.paymentType]);
	const isRetailBanks = useMemo(() => formik.values.bankType === COMMON.BANK_TYPE.RETAIL_BANKS, [formik.values.bankType]);
	const isCorporateBanks = useMemo(() => formik.values.bankType === COMMON.BANK_TYPE.CORPORATE_BANKS, [formik.values.bankType]);
	const bankOptions = useMemo(() => (formik.values.bankType ? banks[formik.values.bankType] : []), [banks, formik.values.bankType]);
	const isValidBankType = useMemo(() => !!formik.values.bankType && bankOptions?.length, [formik.values.bankType, bankOptions]);

	const bankPaymentClassName = useMemo(() => classNames({ payment__card: true, "payment__card--error": formik.errors.bank && formik.touched.bank }), [formik.errors, formik.touched]);

	const expiredCountDown = useMemo(() => secondsToTime(timer), [timer]);

	const onHandleSelectPaymentType = (event) => {
		let type = event.currentTarget.getAttribute("data-type");

		if (type === formik.values.paymentType) type = "";

		formik.setFieldValue("bankType", "");

		formik.setFieldValue("paymentType", type);
	};

	const onHandleSelectBank = (event) => {
		const bank = event.currentTarget.getAttribute("data-type");
		formik.setFieldValue("bank", bank);
	};

	const onHandleSelectBankType = (event) => {
		const type = event.currentTarget.getAttribute("data-type");
		formik.setFieldValue("bankType", type);
	};

	const onlineBankingTabClassName = useMemo(() => {
		return classNames({ "payment__card-header": true, "payment__card-header--active": isOnlinePayment });
	}, [isOnlinePayment]);

	const creditCardTabClassName = useMemo(() => {
		return classNames({ "payment__card-header": true, "payment__card-header--active": isCreditCard });
	}, [isCreditCard]);

	//prettier-ignore
	const bankTypeClassName = useMemo((type) => {
		return classNames({ "payment__bank-type": true, "payment__bank-type--active": isValidBankType });
	}, [isValidBankType]);

	//prettier-ignore
	const retailBankRadioClassName = useMemo((type) => {
		return classNames({ payment__radio: true, "payment__radio--active": isRetailBanks });
	}, [isRetailBanks]);

	//prettier-ignore
	const corporateBankRadioClassName = useMemo((type) => {
		return classNames({ payment__radio: true, "payment__radio--active": isCorporateBanks });
	}, [isCorporateBanks]);

	//prettier-ignore
	const onHandleCheckbox = useCallback((event) => {
		const name = event.currentTarget.name;
		const value = event.currentTarget.checked;
		formik.setFieldValue(name, !value);
	}, [formik]);

	//prettier-ignore
	const onHandleRequestError = useCallback((error) => {
		const rnWebViewPostMessage = window?.directPostMessage;
		const noInternetConnection = error?.code === "ERR_NETWORK";
		
		if(rnWebViewPostMessage) rnWebViewPostMessage(JSON.stringify(error));

		if (noInternetConnection) {
			navigate(pathnames.connectionFailed);
		} else {
			const message = sanitizeError(error);
			if(message) alertRef.current?.onHandleShow(message);
		}
	}, [navigate]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				acceptedTnc: values.tnc,
				orderNumber: payment.paymentCode,
				paymentMethod: {
					type: values.paymentType,
				},
			};

			if(values.bank) {
				payload.paymentMethod.details = {};
				payload.paymentMethod.details.bankId = values.bank;
				payload.paymentMethod.details.bankType = values.bankType === COMMON.BANK_TYPE.CORPORATE_BANKS ? "Corporate" : "Retail";
			}

			if(!isCreditCard) {
				if(isRetailBanks && (payment.amount.amount < 1 || payment.amount.amount > 30000)) {
					setSubmitting(false);
					return alertRef.current?.onHandleShow("Amount must be higher than RM 1.00 and lower than RM 30,000.00");
				}
				
				if(isCorporateBanks && (payment.amount.amount < 2 || payment.amount.amount > 1000000)) {
					setSubmitting(false);
					return alertRef.current?.onHandleShow("Amount must be higher than RM 2.00 and lower than RM 1,000,000.00");
				}
			}
			// if (values.paymentType === COMMON.PAYMENT_TYPE.CREDIT_CARD) {
			// 	const { type } = validateCreditCardType(values.cc.cardNumber);

			// 	payload.paymentMethod.details = {
			// 		creditCardType: type,
			// 		creditCardHolderFullName: values.cc.cardName,
			// 		creditCardNumber: values.cc.cardNumber,
			// 		creditCardSecurityCode: values.cc.cvv,
			// 		creditCardExpirationMonth: values.expiryDate.split("/")[0],
			// 		creditCardExpirationYear: values.expiryDate.split("/")[1],
			// 	};
			// }

			response = await api.post.pay(payload);
		} catch (error) {
			setSubmitting(false);
			onHandleRequestError(error);
		}

		if (response) {
			const rnWebViewPostMessage = window?.directPostMessage;
			
			if(rnWebViewPostMessage) rnWebViewPostMessage(JSON.stringify(response));

			window.location.href = response.data;
		}
	}, [setSubmitting, onHandleRequestError, payment.paymentCode, isCorporateBanks, isCreditCard, isRetailBanks, payment.amount]);

	//prettier-ignore
	const onHandleCancelPayment = useCallback(async () => {
		let response = null;

		try {
			response = await api.patch.cancelPayment(orderNo);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response?.data?.redirectUrl) window.location.href = response.data.redirectUrl;
	}, [orderNo, onHandleRequestError]);

	//prettier-ignore
	const onHandleGetBanks = useCallback(async (order) => {
		const rnWebViewPostMessage = window?.directPostMessage;

		let response = null;

		try {
			response = await api.get.banks(order);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			setLoading(false);

			if (rnWebViewPostMessage) rnWebViewPostMessage(JSON.stringify({ action: COMMON.EVENTS.LOADED }));
		}

		if (response) {
			const paymentSubmitted = response.data?.processed;

			if(paymentSubmitted) {
				window.location.href = response.data.redirectUrl;
			} else {
				const retailBanks = response.data.retailBanks.map((o) => ({ ...o, label: o.displayName, value: o.code }));
				const corporateBanks = response.data.corporateBanks.map((o) => ({ ...o, label: o.displayName, value: o.code }));
				setBanks({ [COMMON.BANK_TYPE.RETAIL_BANKS]: retailBanks, [COMMON.BANK_TYPE.CORPORATE_BANKS]: corporateBanks });
				setPayment({ ...response.data.payment, hideCorporateBank: response.data.hideCorporateBank });
				setPaymentMethods(response.data.paymentMethods);
			}
		}
	}, [onHandleRequestError]);

	const FpxDescription = useCallback(() => {
		return (
			<Fragment>
				By clicking on "Proceed" button, you hereby agree with{" "}
				<a href={process.env.REACT_APP_FPX_TNC_URL} target="_blank" rel="noopener noreferrer">
					FPX’s Terms & Condition
				</a>
			</Fragment>
		);
	}, []);

	useEffect(() => {
		const isUIWebView = () => navigator.userAgent.toLowerCase().match(/\(ip.*applewebkit(?!.*(version|crios))/);
		const receiver = isUIWebView() ? window : document;
		const rnWebViewPostMessage = window?.directPostMessage;
		let counterLoaded = null;

		const onHandlePostMessage = (message) => {
			let data = null;

			try {
				data = JSON.parse(message.data);
			} catch (error) {
				const errorJSON = JSON.stringify(error);
				if (rnWebViewPostMessage) rnWebViewPostMessage(errorJSON);
				else console.log(errorJSON);
			}

			if (data) {
				if (rnWebViewPostMessage) rnWebViewPostMessage(JSON.stringify(data));
				else console.log(JSON.stringify(data));
			}

			if (data?.isMobile) sessionStorage.setItem(COMMON.DEVICE.MOBILE, true);
		};

		receiver.addEventListener("message", onHandlePostMessage);

		counterLoaded = setTimeout(() => {
			if (rnWebViewPostMessage) rnWebViewPostMessage(JSON.stringify({ action: COMMON.EVENTS.LOADED }));
		}, 100);

		return () => {
			clearTimeout(counterLoaded);
			receiver.removeEventListener("message", onHandlePostMessage);
		};
	}, []);

	useEffect(() => {
		if (orderNo) onHandleGetBanks(orderNo);
	}, [orderNo, onHandleGetBanks]);

	useEffect(() => {
		if (loading || !payment.expiredAt) return;

		const secondsInTheFuture = new Date(payment.expiredAt).getTime() / 1000;

		const secondsNow = new Date().getTime() / 1000;

		const remainedTime = Math.round(secondsInTheFuture - secondsNow);

		const onHandleExpiredPayment = async () => {
			let response = null;

			try {
				response = await api.patch.expirePayment(orderNo);
			} catch (error) {
				onHandleRequestError(error);
			}

			if (response?.data?.redirectUrl) window.location.href = response.data.redirectUrl;
		};

		const timerWorker = new Worker(timerCounter);

		timerWorker.postMessage({ turn: "time", remainedTime: remainedTime });

		timerWorker.postMessage({ turn: "on" });

		timerWorker.onmessage = ({ data: { time } }) => {
			if (time <= 0) {
				setTimer(0);

				timerWorker.postMessage({ turn: "off" });

				onHandleExpiredPayment();
			} else {
				setTimer(time);
			}
		};

		return () => timerWorker.postMessage({ turn: "off" });
	}, [loading, payment, orderNo, onHandleRequestError]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.BANKS);
		};
	}, [context]);

	if (loading) return <AppLoading />;

	return (
		<div className="app-page app-payment">
			<form className="payment" onSubmit={formik.handleSubmit}>
				<div className="payment__header">
					<h1 className="payment__title">Select Payment Method</h1>
					<p className="payment__expired">
						Your payment is about to expire in <span>{expiredCountDown}</span>
					</p>
				</div>

				<div className="payment__container">
					<div className="payment__section">
						<div className={bankPaymentClassName}>
							<button type="button" className={onlineBankingTabClassName} data-type={COMMON.PAYMENT_TYPE.ONLINE_BANKING} onClick={onHandleSelectPaymentType} disabled={!paymentMethods[COMMON.PAYMENT_TYPE.ONLINE_BANKING]}>
								<img className="payment__logo" src={fpxLogo} alt="online banking" />
								<p className="payment__text">Online Banking</p>
								<img className="payment__chevron" src={chevronIcon} alt="chevron" />
							</button>

							{isOnlinePayment && (
								<div className={bankTypeClassName}>
									<button type="button" className={retailBankRadioClassName} data-type={COMMON.BANK_TYPE.RETAIL_BANKS} onClick={onHandleSelectBankType}>
										<span className="payment__dot" />
										<p className="payment__bank-name">Individual Account</p>
									</button>

									{!payment.hideCorporateBank && (
										<button type="button" className={corporateBankRadioClassName} data-type={COMMON.BANK_TYPE.CORPORATE_BANKS} onClick={onHandleSelectBankType}>
											<span className="payment__dot" />
											<p className="payment__bank-name">Business Account</p>
										</button>
									)}
								</div>
							)}

							{isValidBankType && (
								<Fragment>
									<ul className="payment__card-body">
										{bankOptions.map((o) => {
											const isActive = formik.values.bank === o.value;
											const itemClassName = classNames({ "payment__card-item": true, "payment__card-item--active": isActive });

											return (
												<li key={o.code} className={itemClassName}>
													<button className="payment__bank" type="button" data-type={o.value} disabled={!o.active} onClick={onHandleSelectBank}>
														<p className="payment__bank-name">{o.label}</p>

														{isActive && <img className="payment__checked" src={checkIcon} alt="checked icon" />}
													</button>
												</li>
											);
										})}
									</ul>

									<div className="payment__tnc">
										<AppCheckbox name="tnc" label={<FpxDescription />} value={formik.values.tnc} onClick={onHandleCheckbox} />
									</div>
								</Fragment>
							)}
						</div>

						<p className="payment__error">{(formik.errors.bank && formik.touched.bank) || (formik.touched.tnc && formik.errors.tnc) ? formik.errors.bank || formik.errors.tnc : ""}</p>

						<div className="payment__card">
							<div type="button" className={creditCardTabClassName} data-type={COMMON.PAYMENT_TYPE.CREDIT_CARD} onClick={onHandleSelectPaymentType} disabled={!paymentMethods[COMMON.PAYMENT_TYPE.CREDIT_CARD]}>
								<img className="payment__logo" src={creditCardIcon} alt="online banking" />
								<p className="payment__text">Credit Card</p>
								<div className="payment__cc">
									<AppCheckbox name="cc" value={isCreditCard} disabled onClick={() => {}} />
								</div>
							</div>

							{
								/* {isCreditCard && ( */
								// <div className="payment__card-body payment__card-body--credit-card">
								// 	{/* prettier-ignore */}
								// 	<AppMaskingInput name="cc.cardNumber" label="Card Number" placeholder="Placeholder" value={formik.values.cc.cardNumber} error={formik.errors.cc?.cardNumber} touched={formik.touched.cc?.cardNumber} onChange={formik.handleChange} format={COMMON.CREDIT_CARD_FORMAT.CARD_NO} />
								// 	{/* prettier-ignore */}
								// 	<AppInput name="cc.cardName" label="Name on card" placeholder="Placeholder" value={formik.values.cc.cardName} error={formik.errors.cc?.cardName} touched={formik.touched.cc?.cardName} onChange={formik.handleChange} />
								// 	<div className="payment__card-wrapper">
								// 		{/* prettier-ignore */}
								// 		<AppMaskingInput name="cc.expiryDate" label="Expiry Date" placeholder="(MM/YY)" value={formik.values.cc.expiryDate} error={formik.errors.cc?.expiryDate} touched={formik.touched.cc?.expiryDate} onChange={formik.handleChange} format={COMMON.CREDIT_CARD_FORMAT.EXPIRY_DATE} />
								// 		{/* prettier-ignore */}
								// 		<AppInput type="number" name="cc.cvv" label="CVV" placeholder="CVV" value={formik.values.cc.cvv} error={formik.errors.cc?.cvv} touched={formik.touched.cc?.cvv} onChange={formik.handleChange} />
								// 	</div>
								// </div>
								/* )} */
							}
						</div>
					</div>

					<div className="payment__section payment__section--total">
						<div className="payment__total-payment">
							<ul className="payment__list">
								<li className="payment__item">
									<p className="payment__text">Payment</p>
								</li>
								<li className="payment__item">
									<p className="payment__text">Total Payable:</p>
									<p className="payment__text">
										{payment?.amount?.currencyCode || ""} {formatCurrency(payment?.amount?.amount)}
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="payment__footer">
					{/* prettier-ignore */}
					<button className="payment__button payment__button--outline" type="button" disabled={disabledSubmit} onClick={onHandleCancelPayment}>Cancel</button>
					{/* prettier-ignore */}
					<button className="payment__button" type="submit" disabled={disabledSubmit}>Pay now</button>
				</div>
			</form>

			<AppModalAlert ref={alertRef} />
		</div>
	);
};

export default PagePayment;
