import React from "react";
import { RouterProvider } from "react-router-dom";

import routes from "routes/routes";
import InterceptorProvider from "contexts/with-interceptor-provider";

const App = () => {
	return (
		<InterceptorProvider>
			<RouterProvider router={routes} />
		</InterceptorProvider>
	);
};

export default App;
