const formatCurrencyPattern = (price = 0) => {
	const original = price.toString();
	const value = original.replace(/[^0-9.]+/g, "");

	if (value) {
		let arrayNumbers = value.split(".");
		arrayNumbers = arrayNumbers.slice(0, 2);

		const decimal = arrayNumbers[1];
		const number = arrayNumbers[0];

		if (decimal && decimal.length > 2) arrayNumbers[1] = decimal.slice(0, 2);

		arrayNumbers[0] = number.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		if (arrayNumbers.length === 1) arrayNumbers.push("00");

		const updatedValues = arrayNumbers.join(".");

		price = updatedValues;
	} else {
		price = "";
	}

	return price;
};

export const formatCurrency = (price = 0) => {
	const currency = parseFloat(price).toFixed(2);
	return currency.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export default formatCurrencyPattern;
