import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import COMMON from "common";
import encodeQueryParams from "common/encode-query-params";
import queryParamsEntries from "common/query-params-entries";
import failedIcon from "assets/images/failed-icon.png";
import successIcon from "assets/images/success-icon.png";
import classNames from "common/class-names";

const PageStatus = () => {
	const [searchParams] = useSearchParams();
	const intervalCounter = useRef();
	const [counter, setCounter] = useState(5);
	const status = useMemo(() => searchParams.get("status"), [searchParams]);
	const toRedirect = useMemo(() => searchParams.get("toRedirect") === "true", [searchParams]);
	const params = useMemo(() => queryParamsEntries(searchParams), [searchParams]);
	const redirectURL = useMemo(() => searchParams.get("x-redirect-url"), [searchParams]);
	const isSuccess = useMemo(() => status === COMMON.PAYMENT_STATUS.SUCCESS || status === COMMON.PAYMENT_STATUS.B2b_SUCCESS, [status]);
	const message = useMemo(() => searchParams.get("message") || "Something went wrong. Don’t worries! Let’s try again.", [searchParams]);

	const onHandleBackToMerchant = useCallback(() => {
		if (!toRedirect) return;

		if (redirectURL !== "null" && redirectURL) window.location.href = redirectURL + encodeQueryParams(params);

		if (window?.directPostMessage) {
			const payload = { action: isSuccess ? COMMON.EVENTS.SUCCESS : COMMON.EVENTS.FAILED, message: !isSuccess ? message : "" };

			window.directPostMessage(JSON.stringify(payload));
		}
	}, [params, toRedirect, redirectURL, message, isSuccess]);

	const transactionItems = useMemo(() => {
		const getPaymentMethod = (type) => {
			switch (type) {
				case COMMON.PAYMENT_TYPE.CREDIT_CARD:
					return "Credit Card";
				case COMMON.PAYMENT_TYPE.ONLINE_BANKING:
					return "Online Banking";
				default:
					return "-";
			}
		};

		const items = [
			{ label: "Transaction Status", value: isSuccess ? "SUCCESSFUL" : "UNSUCCESSFUL", status: isSuccess },
			{ label: "FPX Transaction ID", value: params.transactionId || "-" },
			{ label: "Seller Order Number ", value: params.orderNumber },
			{ label: "Transaction Date & Time", value: params.transactionTimestamp || "-" },
			{ label: "Payment Method", value: getPaymentMethod(params["paymentMethod"]) },
			params.bankName && { label: "Bank Name", value: params.bankName },
			{ label: "Transaction Amount", value: params.amount },
		];

		return items.filter((o) => o);
	}, [isSuccess, params]);

	useEffect(() => {
		intervalCounter.current = setInterval(() => {
			setCounter((prev) => {
				if (prev - 1 < 0) {
					clearInterval(intervalCounter.current);

					onHandleBackToMerchant();

					return 0;
				} else {
					return (prev -= 1);
				}
			});
		}, 1000);

		return () => {
			clearInterval(intervalCounter.current);
		};
	}, [onHandleBackToMerchant]);

	return (
		<div className="app-page page-status">
			<div className="status">
				<img className="status__icon" src={isSuccess ? successIcon : failedIcon} alt="processing" />
				<p className="status__title">{isSuccess ? "Payment Successful" : "Payment Unsuccessful"}</p>
				<p className="status__description">{isSuccess ? "Thank you for your payment" : message}</p>

				<ul className="status__list">
					{transactionItems.map((o) => {
						const hasStatusProps = o.hasOwnProperty("status");
						const valueClassName = classNames({ status__value: true, "status__value--success": hasStatusProps && o.status, "status__value--failed": hasStatusProps && !o.status });

						return (
							<li className="status__item" key={o.label}>
								<p className="status__text">{o.label}</p>
								<p className={valueClassName}>{o.value}</p>
							</li>
						);
					})}
				</ul>

				{toRedirect && (
					<div className="status__button-container">
						<button type="button" className="status__button" onClick={onHandleBackToMerchant} disabled={counter}>
							Back to merchant {counter ? `(${counter})` : ""}
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default PageStatus;
