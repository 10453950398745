const sanitizeError = (error = "") => {
	const response = error?.response?.data;
	const statusCode = error.response?.status;

	if (statusCode === 403) return "";

	if (response?.exceptionMessage) {
		return response.exceptionMessage;
	} else if (response?.message) {
		const isCancelled = error.message === "canceled";

		if (isCancelled) return "";
		return response?.message;
	} else {
		return error?.message || "";
	}
};

export default sanitizeError;
