import React, { memo } from "react";

import logo from "assets/images/logo.png";

const AppLayout = ({ children }) => {
	return (
		<div className="app-layout">
			<div className="layout">
				<main className="layout__body">{children}</main>

				<footer className="layout__footer">
					<p className="layout__powered-by">Powered by</p>
					<img className="layout__logo" src={logo} alt="direct integrate" />
				</footer>
			</div>
		</div>
	);
};

export default memo(AppLayout);
