import { createBrowserRouter } from "react-router-dom";

import pathnames from "routes/pathnames";
import AppLayout from "components/app-layout";
import PagePayment from "pages/page-payment";
import PageStatus from "pages/page-status";
import PageConnectionFailed from "pages/page-connection-failed";

const routes = [
	{
		path: pathnames.payment,
		element: <PagePayment />,
	},
	{
		path: pathnames.status,
		element: <PageStatus />,
	},
	{
		path: pathnames.connectionFailed,
		element: <PageConnectionFailed />,
	},
];

const enrichedRouters = routes.map(({ ...res }) => {
	return { ...res, element: <AppLayout>{res.element}</AppLayout> };
});

export default createBrowserRouter(enrichedRouters);
